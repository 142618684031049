import { mockEmployees } from '../../main/document-form/MockData';
import axios from 'axios';
import moment from 'moment';

class ControlService {

     async getEvidence(data) {
        return axios.post(process.env.REACT_APP_API_HOST + "/evidence/", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

    async saveConfig(data) {
        return axios.post(process.env.REACT_APP_API_HOST + "/control/save-config", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

    async getTypeCert(data) {
        return axios.get(
            process.env.REACT_APP_API_HOST + `/control/type-cert/${data}`,
        ).catch(function (error) {
            console.log(error);
        })
    }

    async saveControlSign(data) {
        return axios.post(process.env.REACT_APP_API_HOST + "/control/saveControlSign", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

    async getTypeAuthConfig(id) {
        return axios.get(
            process.env.REACT_APP_API_HOST + `/control/type-auth/${id}`,
        ).catch(function (error) {
            console.log(error);
        })
    }

    async getTypeAuthSelect() {
        return await axios.post(
            process.env.REACT_APP_API_HOST + `/control/type-auth`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getTypeAuth() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/control/type-auth`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getControlFirmas(data) {
        return axios.post(process.env.REACT_APP_API_HOST + "/control/getControlFirmas", data)
            .then((response) => {
                return response;
            }).catch((error) => {
                return error.response;
            });
    }

    async getTypeSolicitudes() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/control/type-solicitudes`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getControl() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/control/`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getControlSigners() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/control/signning`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEquibalent() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/control/equibalent`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }
}


const instance = new ControlService()
export default instance