import React from 'react';

export const ConfiguracionesRecibosConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['admin', 'master', 'manager'],
    routes: [{
        path: '/config-invoice',
        component: React.lazy(() =>
            import('./ConfiguracionesRecibos'))
    }]
};