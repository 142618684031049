import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse';
import { LoginConfig } from 'app/main/login/LoginConfig';
import { LogoutConfig } from 'app/main/logout/LogoutConfig';
import { UserRegisterConfig } from 'app/main/user-register/UserRegisterConfig';
import { DocumentListConfig } from 'app/main/document-list/DocumentListConfig';
import { DocumentReportConfig } from 'app/main/document-report/DocumentReportConfig';
import { XMLMockListConfig } from 'app/main/xml-mocklist/XMLMockListConfig';
import { PaymentReceiptConfig } from 'app/main/payment-receipt/PaymentReceiptConfig';
import { FaqPageConfig } from 'app/main/faq/FaqPageConfig';
import { DocumentFormConfig } from 'app/main/document-form/DocumentFormConfig';
import { RequestListConfig } from 'app/main/RequestList/RequestListConfig';
import { RequestRRHHConfig } from 'app/main/RequestRRHH/RequestRRHHConfig';
import { RegisterRequestConfig } from 'app/main/register-request/RegisterRequestConfig';
import { ModernInvoicePageConfig } from 'app/main/invoice/ModernInvoicePageConfig';
import { KudeReciboHaberesPageConfig } from 'app/main/kude-recibohaberes/KudeReciboHaberesPageConfig';
import { XMLConfig } from 'app/main/xml/XMLConfig';

import { AmonestacionesConfig } from 'app/main/amonestaciones/AmonestacionesConfig';
import { ListAmonestacionesConfig } from 'app/main/list-amonestaciones/ListAmonestacionesConfig';
import { AbandonoConfig } from 'app/main/abandono/AbandonoConfig';
import { ListAbandonoConfig } from 'app/main/list-abandono/ListAbandonoConfig';
import { EnfermedadesProfesionalesConfig } from 'app/main/enfermedadesprofesionales/EnfermedadesProfesionalesConfig';
import { ListEnfermedadesProfesionalesConfig } from 'app/main/list-enfermedadesprofesionales/ListEnfermedadesProfesionalesConfig';
import { VacacionesConfig } from 'app/main/vacaciones/VacacionesConfig';
import { ListVacacionesConfig } from 'app/main/list-vacaciones/ListVacacionesConfig';
import { PermisosConfig } from 'app/main/permisos/PermisosConfig';
import { ListPermisosConfig } from 'app/main/list-permisos/ListPermisosConfig';
import { SuspensionesConfig } from 'app/main/suspensiones/SuspensionesConfig';
import { ListSuspensionesConfig } from 'app/main/list-suspensiones/ListSuspensionesConfig';
import { PreavisosConfig } from 'app/main/preavisos/PreavisosConfig';
import { ListPreavisosConfig } from 'app/main/list-preavisos/ListPreavisosConfig';
import { AusenciasConfig } from 'app/main/ausencias/AusenciasConfig';
import { ListAusenciasConfig } from 'app/main/list-ausencias/ListAusenciasConfig';
import { ApercibimientoConfig } from 'app/main/apercibimiento/ApercibimientoConfig';
import { ListApercibimientoConfig } from 'app/main/list-apercibimiento/ListApercibimientoConfig';
import { AccidentesLaboralesConfig } from 'app/main/accidenteslaborales/AccidentesLaboralesConfig';
import { ListAccidentesLaboralesConfig } from 'app/main/list-accidenteslaborales/ListAccidentesLaboralesConfig';
import { KudeAmonestacionConfig } from 'app/main/kude-amonestacion/KudeAmonestacionConfig';
import { KudeAbandonoConfig } from 'app/main/kude-abandono/KudeAbandonoConfig';
import { KudeAccidentesLaboralesConfig } from 'app/main/kude-accidenteslaborales/KudeAccidentesLaboralesConfig';
import { KudeApercibimientoConfig } from 'app/main/kude-apercibimiento/KudeApercibimientoConfig';
import { KudeAusenciasConfig } from 'app/main/kude-ausencias/KudeAusenciasConfig';
import { KudeEnfermedadesProfesionalesConfig } from 'app/main/kude-enfermedadesprofesionales/KudeEnfermedadesProfesionalesConfig';
import { KudePermisosConfig } from 'app/main/kude-permisos/KudePermisosConfig';
import { KudeSuspensionesConfig } from 'app/main/kude-suspensiones/KudeSuspensionesConfig';
import { KudeVacacionesConfig } from 'app/main/kude-vacaciones/KudeVacacionesConfig';
import { KudePreavisosConfig } from 'app/main/kude-preavisos/KudePreavisosConfig';
import { EmpleadoUpdateConfig } from 'app/main/empleado-update/EmpleadoUpdateConfig';
import { EmailConfigConfig } from 'app/main/email-config/EmailConfigConfig';
import { EmailServerConfig } from 'app/main/email-server-config/EmailServerConfig';
import { LogsConfig } from 'app/main/logs/LogsConfig';
import { UserGroupListConfig } from 'app/main/user-group-list/UserGroupListConfig';
import { UserCargosListConfig } from 'app/main/user-cargos-list/UserCargosListConfig';
import { UserDepartListConfig } from 'app/main/user-depart-list/UserDepartListConfig';
import { UserPatronalesListConfig } from 'app/main/user-patronales-list/UserPatronalesListConfig';
import { ConceptosListConfig } from 'app/main/conceptos-list/ConceptosListConfig';
import { CompanyListConfig } from 'app/main/company-list/CompanyListConfig';
import { DocumentsListConfig } from 'app/main/document-type-list/DocumentsTypeListConfig';
import { ConceptosUpdateConfig } from 'app/main/conceptos-update/ConceptosUpdateConfig';
import { CompanyUpdateConfig } from 'app/main/company-update/CompanyUpdateConfig';
import { PatronalUpdateConfig } from 'app/main/patronal-update/PatronalUpdateConfig';
import { DocumentUpdateConfig } from 'app/main/document-type-update/DocumentUpdateConfig';
import { EquivalenciasListConfig } from 'app/main/equivalencias-list/EquivalenciasListConfig';
import { EquivalenciasUpdateConfig } from 'app/main/equivalencias-update/EquivalenciasUpdateConfig';
import { UserGroupUpdateConfig } from 'app/main/user-group-update/UserGroupUpdateConfig';
import { UserDepartUpdateConfig } from 'app/main/user-depart-update/UserDepartUpdateConfig';
import { UserCargosUpdateConfig } from 'app/main/user-cargos-update/UserCargosUpdateConfig';
import { UserCertificateConfig } from 'app/main/user-certificate/UserCertificateConfig';
import { UserFirmaConfig } from 'app/main/user-firma-holograph/UserFirmaHolographConfig';
import { ListNotificacionesConfig } from 'app/main/list-notificaciones/ListNotificacionesConfig';
import { KudeNotificacionConfig } from 'app/main/kude-notificacion/KudeNotificacionConfig';
import { ListNotificacionesMessagesConfig } from 'app/main/list-notificaciones-messages/ListNotificacionesMessagesConfig';
import { ComprobantePageConfig } from 'app/main/comprobante/ComprobantePageConfig';
import { ComunicacionConfig } from 'app/main/comunicacion/ComunicacionConfig';
import { Configuraciones } from 'app/main/configuraciones/Configuraciones';
import { MantenimientoUsuarios } from 'app/main/mantenimiento-usuarios/MantenimientoUsuarios';
import { UpdateUsuariosConfig } from 'app/main/user-update/UpdateUsuariosConfig';
import { LoggingListConfig } from 'app/main/logginlist/LoggingListConfig';
import { PenaltyListConfig } from 'app/main/penaltylist/PenaltyListConfig';
import { LoadLogsConfig } from 'app/main/load-logs/LoadLogsConfig';
import { DashBoardConfig } from 'app/main/dashboard/DashBoardConfig';
import { ZRouteConfig } from 'app/main/zroute/zrouteConfig';
import { NotificacionesConfig } from 'app/main/notificaciones/NotificacionesConfig';
import { NotificacionesListConfig } from 'app/main/notificaciones-list/NotificacionesListConfig';
import { ConfiguracionesFirmasConfig } from 'app/main/configuraciones-firmas/ConfiguracionesFirmasConfig';
import { NotificacionesListDetailsConfig } from 'app/main/notificaciones-details/NotificacionesListDetailsConfig';
import { TiposNotificacionesListConfig } from 'app/main/tipos_notificaciones/TiposNotificacionesListConfig';
import { TiposNotificacionesUpdateConfig } from 'app/main/tipos-notificaciones-update/TiposNotificacionesUpdateConfig';
import { ConfiguracionesRecibosConfig } from 'app/main/configuraciones-recibos/ConfiguracionesRecibosConfig';

const routeConfigs = [
    TiposNotificacionesListConfig,
    TiposNotificacionesUpdateConfig,
    LoginConfig,
    LogoutConfig,
    UserRegisterConfig,
    DocumentListConfig,
    Configuraciones,
    ConfiguracionesRecibosConfig,
    MantenimientoUsuarios,
    UpdateUsuariosConfig,
    DocumentReportConfig,
    DocumentUpdateConfig,
    ConfiguracionesFirmasConfig,
    XMLMockListConfig,
    PaymentReceiptConfig,
    FaqPageConfig,
    RegisterRequestConfig,
    NotificacionesListConfig,
    DocumentFormConfig,
    RequestListConfig,
    RequestRRHHConfig,
    XMLConfig,
    ModernInvoicePageConfig,
    KudeReciboHaberesPageConfig,
    AmonestacionesConfig,
    ListAmonestacionesConfig,
    AbandonoConfig,
    ListAbandonoConfig,
    EnfermedadesProfesionalesConfig,
    ListEnfermedadesProfesionalesConfig,
    VacacionesConfig,
    NotificacionesConfig,
    NotificacionesListDetailsConfig,
    ListVacacionesConfig,
    PermisosConfig,
    ListPermisosConfig,
    SuspensionesConfig,
    ListSuspensionesConfig,
    PreavisosConfig,
    ListPreavisosConfig,
    AusenciasConfig,
    ListAusenciasConfig,
    ApercibimientoConfig,
    ListApercibimientoConfig,
    AccidentesLaboralesConfig,
    ListAccidentesLaboralesConfig,
    KudeAmonestacionConfig,
    KudeAbandonoConfig,
    KudeAccidentesLaboralesConfig,
    KudeApercibimientoConfig,
    KudeAusenciasConfig,
    KudeEnfermedadesProfesionalesConfig,
    KudePermisosConfig,
    KudeSuspensionesConfig,
    KudeVacacionesConfig,
    KudePreavisosConfig,
    EmpleadoUpdateConfig,
    EmailConfigConfig,
    EmailServerConfig,
    LogsConfig,
    ConceptosListConfig,
    CompanyListConfig,
    CompanyUpdateConfig,
    PatronalUpdateConfig,
    DocumentsListConfig,
    EquivalenciasListConfig,
    ConceptosUpdateConfig,
    EquivalenciasUpdateConfig,
    UserCargosListConfig,
    UserDepartListConfig,
    UserPatronalesListConfig,
    UserGroupListConfig,
    UserGroupUpdateConfig,
    UserDepartUpdateConfig,
    UserCargosUpdateConfig,
    UserCertificateConfig,
    UserFirmaConfig,
    NotificacionesConfig,
    ListNotificacionesConfig,
    ListNotificacionesMessagesConfig,
    KudeNotificacionConfig,
    ComprobantePageConfig,
    ComunicacionConfig,
    LoggingListConfig,
    PenaltyListConfig,
    LoadLogsConfig,
    DashBoardConfig,
    ZRouteConfig
];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => < Redirect to="/zroute" />
    },
    {
        component: () => < Redirect to="/pages/errors/error-404" />
    }
];

export default routes;