const navigationConfig = [{
    'id': 'applications',
    'title': 'Menú',
    'type': 'group',
    'icon': 'apps',
    'children': [{
        'id': 'dashboard',
        'title': 'DashBoard',
        'type': 'item',
        'icon': 'chat',
        'url': '/dashboard',
        'auth': ['master', 'rh', 'rh_not_signer', 'director', 'admin']
    },
    {
        'id': 'company-list',
        'title': 'Empresas',
        'type': 'item',
        'icon': 'business',
        'url': '/company-list',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'sucursales-list',
        'title': 'Sucursales',
        'type': 'item',
        'icon': 'business',
        'url': '/sucursales-list',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'depart',
        'title': 'Departamentos',
        'type': 'item',
        'icon': 'list',
        'url': '/departamentos-list',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'cargos',
        'title': 'Cargos',
        'type': 'item',
        'icon': 'list',
        'url': '/cargos-list',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'patronales',
        'title': 'Patronales',
        'type': 'item',
        'icon': 'list',
        'url': '/patronales-list',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'employees',
        'title': 'Empleados',
        'type': 'item',
        'icon': 'person',
        'url': '/employees',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'conceptos',
        'title': 'Conceptos',
        'type': 'item',
        'icon': 'list',
        'url': '/conceptos-list',
        'auth': ['admin']
    },
    {
        'id': 'equivalencias',
        'title': 'Equivalencias de Conceptos',
        'type': 'item',
        'icon': 'list',
        'url': '/equivalencias-list',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'documents-type-list',
        'title': 'Tipos de Pagos',
        'type': 'item',
        'icon': 'list',
        'auth': ['rh', 'rh_not_signer', 'admin'],
        'url': '/documents-type-list',
    },
    {
        'id': 'salary',
        'title': 'Documentos de Haberes',
        'type': 'collapse',
        'icon': 'money',
        'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin', 'manager'],
        'children': [{
            'id': 'payment-receipts',
            'title': 'Agregar Recibo',
            'type': 'item',
            'icon': 'insert_drive_file',
            'url': '/payment-receipt',
            'auth': ['rh', 'rh_not_signer', 'admin']
        },
        {
            'id': 'document-list',
            'title': 'Recibos de Haberes',
            'type': 'item',
            'icon': 'document-list',
            'url': '/document-list',
            'auth': [ 'rh', 'rh_not_signer', 'director', 'funcionario', 'admin', 'manager']
        },
        {
            'id': 'document-report',
            'title': 'Control de Recibos',
            'type': 'item',
            'icon': 'document-list',
            'url': '/document-report',
            'auth': ['rh', 'rh_not_signer', 'admin', 'manager']
        },
        {
            'id': 'load-logs',
            'title': 'Log de Carga',
            'type': 'item',
            'icon': 'help_outline',
            'url': '/loadlogs',
            'auth': ['master', 'auditor', 'rh', 'rh_not_signer', 'admin']
        }
        ]
    },
    {
        'id': 'chat',
        'title': 'Notificaciones',
        'type': 'collapse',
        'icon': 'chat',
        'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin'],
        'children': [
            {
                'id': 'Tipos de Notificaciones',
                'title': 'Tipos de Notificaciones',
                'type': 'item',
                'icon': 'document-list',
                'url': '/tipo-notificacion',
                'auth': ['rh', 'rh_not_signer', 'admin']
            },
            {
                'id': 'Registrar Notificacion',
                'title': 'Registrar Notificacion',
                'type': 'item',
                'icon': 'document-list',
                'url': '/notificaciones',
                'auth': ['rh', 'rh_not_signer', 'director', 'admin']
            },
            {
                'id': 'Listar Notificaciones',
                'title': 'Listar Notificaciones',
                'type': 'item',
                'icon': 'document-list',
                'url': '/notificaciones-list',
                'auth': ['funcionario', 'rh', 'rh_not_signer', 'director', 'admin']
            }

        ]
    },
    {
        'id': 'comunications',
        'title': 'Comunicaciones',
        'type': 'collapse',
        'icon': 'money',
        //'auth': ['master', 'rh', 'rh_not_signer'],
        'auth': process.env.REACT_APP_HIDE_COMUNICATIONS ? [] : [],
        'children': [{
            'id': 'AccidentesLaborales',
            'title': 'Accidentes Laborales',
            'type': 'item',
            'icon': 'person',
            'url': '/list-accidenteslaborales'
        },
        {
            'id': 'Listar Amonestaciones',
            'title': 'Listar Amonestaciones',
            'type': 'item',
            'icon': 'person',
            'url': '/list-amonestaciones'
        },
        {
            'id': 'Registrar Amonestación',
            'title': 'Registrar Amonestación',
            'type': 'item',
            'icon': 'person',
            'auth': ['rh', 'rh_not_signer'],
            'url': '/amonestaciones'
        },
        {
            'id': 'Abandono',
            'title': 'Abandono',
            'type': 'item',
            'icon': 'person',
            'url': '/list-abandono'
        },
        {
            'id': 'EnfermedadesProfesionales',
            'title': 'Enfermedades Profesionales',
            'type': 'item',
            'icon': 'person',
            'url': '/list-enfermedadesprofesionales'
        },
        {
            'id': 'Vacaciones',
            'title': 'Vacaciones',
            'type': 'item',
            'icon': 'person',
            'url': '/list-vacaciones'
        },
        {
            'id': 'Permisos',
            'title': 'Permisos',
            'type': 'item',
            'icon': 'person',
            'url': '/list-permisos'
        },
        {
            'id': 'Listar Suspensiones',
            'title': 'Listar Suspensiones',
            'type': 'item',
            'icon': 'person',
            'url': '/list-suspensiones'
        },
        {
            'id': 'Registrar Suspensión',
            'title': 'Registrar Suspensión',
            'type': 'item',
            'icon': 'person',
            'auth': ['rh', 'rh_not_signer'],
            'url': '/suspensiones'
        },
        {
            'id': 'Listar Preavisos',
            'title': 'Listar Preavisos',
            'type': 'item',
            'icon': 'person',
            'url': '/list-preavisos'
        },
        {
            'id': 'Registrar Preaviso',
            'title': 'Registrar Preaviso',
            'type': 'item',
            'icon': 'person',
            'url': '/preavisos'
        },
        {
            'id': 'Ausencias',
            'title': 'Ausencias',
            'type': 'item',
            'icon': 'person',
            'url': '/list-ausencias'
        },
        {
            'id': 'Apercibimiento',
            'title': 'Apercibimiento',
            'type': 'item',
            'icon': 'person',
            'url': '/list-apercibimiento'
        },

        {
            'id': 'Listar Apercibimientos',
            'title': 'Listar Apercibimientos',
            'type': 'item',
            'icon': 'person',
            'url': '/list-apercibimiento'
        },
        {
            'id': 'Registrar Apercibimiento',
            'title': 'Registrar Apercibimiento',
            'type': 'item',
            'icon': 'person',
            'url': '/apercibimiento'
        },
        {
            'id': 'RegisterRequest',
            'title': 'Registrar solicitud',
            'type': 'item',
            'icon': 'lock',
            'url': '/form/register',
            'auth': ['rh', 'rh_not_signer', 'director', 'funcionario']
        },
        {
            'id': 'RequestList',
            'title': 'Lista de solicitudes',
            'type': 'item',
            'icon': 'lock',
            'url': '/RequestList',
            'auth': ['rh', 'rh_not_signer', 'director', 'funcionario', 'admin']
        },
        {
            'id': 'RequestRRHH',
            'title': 'Lista de solicitudes RRHH',
            'type': 'item',
            'icon': 'lock',
            'url': '/RequestRRHH',
            'auth': ['master', 'rh', 'rh_not_signer', 'admin']
        }
        ]
    },
    {
        'id': 'config-invoice',
        'title': 'Configuracion de recibos',
        'type': 'item',
        'icon': 'list-bullet',
        'auth': ['master', 'admin'],
        'url': '/config-invoice',
    },
    {
        'id': 'user-certificate',
        'title': 'Certificado del Usuario',
        'type': 'item',
        'icon': 'help_outline',
        'auth': ['master', 'admin'],
        'url': '/user-certificate',
    },
    {
        'id': 'user-firma-holograph',
        'title': 'Firma Holografa',
        'type': 'item',
        'icon': 'person',
        'auth': ['master', 'admin'],
        'url': '/user-firma-holograph',
    },
    {
        'id': 'control_sign',
        'title': 'Control de Firmas',
        'type': 'item',
        'icon': 'edit',
        'auth': ['admin', 'rh'],
        'url': '/control-sign'
    },
    {
        'id': 'faq',
        'title': 'FAQ',
        'type': 'item',
        'icon': 'help_outline',
        'url': '/faq'
    },
    {
        'id': 'login-component',
        'title': 'Iniciar Sesión',
        'type': 'item',
        'icon': 'lock',
        'url': '/login',
        'auth': ['guest']
    },
    {
        'id': 'email-config',
        'title': 'Configuración de E-mail',
        'type': 'collapse',
        'icon': 'email',
        'auth': ['admin', 'rh', 'rh_not_signer'],
        'children': [{
            'id': 'email-config-server',
            'title': 'Servidor de Correo',
            'type': 'item',
            'icon': 'devices',
            'url': '/email-config-server',
            'auth': ['admin']
        },
        {
            'id': 'notification-reminder',
            'title': 'Notificaciones por correo',
            'type': 'item',
            'icon': 'drafts',
            'url': '/email-config',
            'auth': ['master', 'rh', 'rh_not_signer', 'admin']
        }
        ]
    },
    {
        'id': 'configuration',
        'title': 'Configuraciones',
        'type': 'item',
        'icon': 'build',
        'auth': ['admin'],
        'url': '/configuraciones',
    },
    {
        'id': 'user-maintenance',
        'title': 'Mantenimiento de Usuarios',
        'type': 'item',
        'icon': 'build',
        'auth': ['master', 'admin'],
        'url': '/user-maintenance',
    },
    {
        'id': 'logs',
        'title': 'Log de Acciones',
        'type': 'item',
        'icon': 'help_outline',
        'url': '/logs',
        'auth': ['master', 'rh', 'auditor', 'admin']
    },
    {
        'id': 'comunicacion',
        'title': 'Comunicación MTESS',
        'type': 'item',
        'icon': 'email',
        'url': '/comunicacion',
        'auth': ['rh', 'rh_not_signer', 'admin']
    },
    {
        'id': 'mtess-verify',
        'title': 'Consultas al MTESS',
        'type': 'collapse',
        'icon': 'help_outline',
        'auth': ['rh', 'rh_not_signer', 'admin'],
        'children': [{
            'id': 'request-hash',
            'title': 'Verificación de incersión',
            'type': 'item',
            'icon': 'help_outline',
            'url': '/logging',
            'auth': ['rh', 'rh_not_signer', 'admin']
        },
        {
            'id': 'penalty',
            'title': 'Consulta de multas',
            'type': 'item',
            'icon': 'help_outline',
            'url': '/penalty',
            'auth': ['rh', 'rh_not_signer', 'admin']
        },
        ]
    },
    ]
}];

export default navigationConfig;